import React from "react"
// import { Link } from "gatsby"
// import { links } from "../Header/Header"

import * as styles from "./footer.module.scss"

import Twitter from "../../images/socialIcons/twitter.inline.svg"
import Instagram from "../../images/socialIcons/instagram.inline.svg"
import Facebook from "../../images/socialIcons/facebook.inline.svg"

const socials = [
  {
    icon: Twitter,
    link: "https://twitter.com/GenieDelivery",
  },
  {
    icon: Facebook,
    link: "https://www.facebook.com/geniedeliveryUK",
  },
  {
    icon: Instagram,
    link: "https://instagram.com/genie.delivery",
  },
]

const footerLinks = [
  {
    displayText: "Get In Touch",
    link: "/#",
  },
  {
    displayText: "Privacy",
    link: "/#",
  },
]

const Footer = (): JSX.Element => {
  return (
    <div className={styles.container}>
      <div className={styles.socials}>
        {socials.map((social) => {
          const Icon = social.icon
          return (
            <a
              key={social.link}
              className={styles.socialIcon}
              href={social.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon />
            </a>
          )
        })}
      </div>
      {/* <div className={styles.contactEmailContainer}>
        <p className={styles.contactEmail}>helpthemhelpus.co.uk </p>
      </div>
      <div className={styles.linkContainer}>
        {footerLinks.map(link => (
          <Link
            key={link.link}
            to={`/${link.link}`}
            className={styles.footerLink}
          >
            {link.displayText}
          </Link>
        ))}
      </div> */}
      <p className={styles.copyright}>
        © Genie Delivery Limited {new Date().getFullYear()}
      </p>
      <p className={styles.copyright}>Made with love and snacks in Cambridge</p>
    </div>
  )
}

export default Footer
