import * as React from "react"
import { useState } from "react"
import * as styles from "./popoutMenu.module.scss"
import { Link } from "gatsby"
import classnames from "classnames"

import inactiveMenuIcon from "../../../images/menuImage.png"
import darkMenu from "../../../images/darkMenu.svg"
import closeIcon from "../../../images/CrossIcon.svg"

const links = [
  {
    display: "Home",
    to: "/",
  },
  {
    display: "About",
    to: "/about",
  },
  {
    display: "Ambassadors",
    to: "/ambassadors",
  },
  {
    display: "Riders",
    to: "/riders",
  },
  {
    display: "Guide to Cambridge",
    to: "/cambridge-guide",
  },
  {
    display: "FAQ",
    to: "/FAQ",
  },
  {
    display: "Privacy Policy",
    to: "/privacy-policy",
    small: true,
  },
  {
    display: "Terms Of Service",
    to: "/terms-of-service",
    small: true,
  },
]

const PopoutMenu = ({ dark }: { dark?: boolean }): JSX.Element => {
  const [active, setActive] = useState<boolean>(false)

  return (
    <div className={styles.menuWrapper}>
      <div onClick={() => setActive(true)} className={styles.inactiveIcon}>
        <img src={dark ? darkMenu : inactiveMenuIcon} alt="" />
      </div>
      {active ? (
        <div className={styles.activeMenu}>
          <img
            className={styles.closeIcon}
            onClick={() => setActive(false)}
            src={closeIcon}
            alt=""
          />
          <div className={styles.contentWrapper}>
            {links.map((link) => (
              <div key={link.to} className={styles.menuItem}>
                <Link
                  onClick={() => setActive(false)}
                  className={classnames(
                    styles.link,
                    link.small ? styles.small : ""
                  )}
                  to={link.to}
                >
                  {link.display}
                </Link>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </div>
  )
}

export default PopoutMenu
